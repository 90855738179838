import { CSSProperties } from "react"
import { css } from "styled-components/macro"

export function flex({
  flex,
  horizontal,
  reversed,
  wrapReverse,
  wrap,
  vAlign = "normal",
  hAlign = "normal",
}: (
  | {
      horizontal: true
      vAlign?: CSSProperties["alignItems"]
      hAlign?: CSSProperties["justifyContent"]
    }
  | {
      horizontal?: false
      vAlign?: CSSProperties["justifyContent"]
      hAlign?: CSSProperties["alignItems"]
    }
) & {
  flex?: number | [number, number] | string
  reversed?: boolean
  wrapReverse?: boolean
  wrap?: boolean
} = {}) {
  const flexDirection = horizontal
    ? reversed
      ? "row-reverse"
      : "row"
    : reversed
    ? "column-reverse"
    : "column"

  const flexWrap = wrapReverse ? "wrap-reverse" : wrap ? "wrap" : "nowrap"

  return css`
    display: flex;
    flex-flow: ${flexDirection} ${flexWrap};
    flex: ${typeof flex === "number"
      ? `${flex} 0 auto`
      : Array.isArray(flex)
      ? `${flex.join(" ")} auto`
      : flex};
    ${vAlign
      ? horizontal
        ? css`
            align-items: ${vAlign};
          `
        : css`
            justify-content: ${vAlign};
          `
      : ""}
    ${hAlign
      ? horizontal
        ? css`
            justify-content: ${hAlign};
          `
        : css`
            align-items: ${hAlign};
          `
      : ""}
  `
}
