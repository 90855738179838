import { t } from "@lingui/macro"
import { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import styled from "styled-components/macro"

import { staticTr } from "lib/lingui"

import { AppInstallPromptDesktop } from "../components/AppInstallPrompt/AppInstallPromptDesktop"
import { $appPrompt, AppPrompt } from "../components/AppInstallPrompt/AppPrompt"
import { Backdrop } from "../components/Backdrop"
import { GamesBackground } from "../components/GamesBackground/GamesBackground"
import { flex } from "../lib/flex"
import { useScreenInfo } from "../lib/useScreenInfo"

export function NotFoundView() {
  const { isDesktop } = useScreenInfo()

  const setAppPrompt = useSetRecoilState($appPrompt)

  useEffect(() => {
    setAppPrompt({ type: "notFound" })
  }, [setAppPrompt])

  return (
    <Root>
      <GamesBackground />
      <Backdrop behind />
      {isDesktop ? (
        <AppInstallPromptDesktop
          title={staticTr(t`Oops, we can’t find that!`)}
          subtitle={staticTr(
            t`Trying to download Rune? Point your camera at the QR Code`
          )}
        />
      ) : (
        <AppPrompt standalone />
      )}
    </Root>
  )
}

const Root = styled.div`
  ${flex({ flex: 1, vAlign: "center", hAlign: "center" })};
`
