import styled from "styled-components/macro"

import backgroundImg from "./assets/background.png"
import background2xImg from "./assets/background@2x.png"
import background3xImg from "./assets/background@3x.png"

export const GamesBackground = styled.div`
  position: absolute;
  top: -100%;
  left: -100%;
  bottom: -100%;
  right: -100%;
  background-image: url(${window.devicePixelRatio >= 3
    ? background3xImg
    : window.devicePixelRatio >= 2
    ? background2xImg
    : backgroundImg});
  background-repeat: repeat;
  background-size: 1714px 1026px;
  transform: rotate(30deg);
  filter: blur(5.3px);
  z-index: -1;
`
