import { useMediaQuery } from "react-responsive"

const touchSupported = "ontouchstart" in window

export function useScreenInfo() {
  const isDesktop =
    useMediaQuery({ query: "(min-width: 992px)" }) || !touchSupported
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })

  return { isDesktop, isPortrait }
}
