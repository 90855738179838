import { css } from "styled-components/macro"

const fontFamily = css`
  font-family: "GTWalsheimLC", sans-serif;
`

const defaultColor = "#1C002B"
const primaryColor = "#00C0FF"

export const webTheme = {
  colors: {
    primary: primaryColor,
    default: defaultColor,
  },
  text: {
    headingLargeBold: css`
      ${fontFamily};
      color: ${defaultColor};
      font-size: 48px;
      font-weight: bold;
      line-height: 130%;
    `,
    headingBold: css`
      ${fontFamily};
      color: ${defaultColor};
      font-size: 24px;
      font-weight: bold;
      line-height: 130%;
    `,
    subheading: css`
      ${fontFamily};
      color: ${defaultColor};
      font-size: 24px;
      line-height: 130%;
    `,
    helper: css`
      ${fontFamily};
      color: ${defaultColor};
      font-size: 12px;
      line-height: 130%;
    `,
    body: css`
      ${fontFamily};
      color: ${defaultColor};
      font-size: 18px;
      line-height: 130%;
    `,
    bodyBold: css`
      ${fontFamily};
      color: ${defaultColor};
      font-size: 18px;
      font-weight: bold;
      line-height: 130%;
    `,
    label: css`
      ${fontFamily};
      color: ${defaultColor};
      font-size: 15px;
      line-height: 130%;
    `,
    labelBold: css`
      ${fontFamily};
      color: ${defaultColor};
      font-size: 15px;
      font-weight: bold;
      line-height: 130%;
    `,
  },
}
