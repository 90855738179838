import { t } from "@lingui/macro"
import { useCallback, useState, useEffect } from "react"
import { atom, useRecoilState } from "recoil"
import styled, { css } from "styled-components/macro"

import { TranslatedMsg, staticTr } from "lib/lingui"

import { webConfig } from "../../config"
import { webLogger } from "../../lib/logWeb"
import { SimpleCSSTransition } from "../../lib/SimpleCSSTransition"
import { webTheme } from "../../styles/webTheme"
import { Backdrop } from "../Backdrop"
import { PromptBase } from "../PromptBase"

import logoImg from "./assets/logo.png"

export type AppPromptState = {
  type:
    | "gameStats"
    | "gameComments"
    | "friends"
    | "quickMatch"
    | "profile"
    | "notFound"
}

export const $appPrompt = atom<AppPromptState | null>({
  key: "$appPrompt",
  default: null,
})

const animationDuration = 300

const texts: {
  [K in AppPromptState["type"]]: {
    title: TranslatedMsg
    subtitle: TranslatedMsg
    type: "install" | "share"
  }
} = {
  gameStats: {
    title: staticTr(t`Get the app for the full experience!`),
    subtitle: staticTr(
      t`See your stats, try the latest games and play them with friends 🎮`
    ),
    type: "install",
  },
  gameComments: {
    title: staticTr(t`Join the conversation!`),
    subtitle: staticTr(
      t`Install Rune to write comments, find friends and play games together 🗣️`
    ),
    type: "install",
  },
  friends: {
    title: staticTr(t`Play with your friends on the app!`),
    subtitle: staticTr(
      t`Install the app to play amazing games with your friends 🎮`
    ),
    type: "install",
  },
  quickMatch: {
    title: staticTr(t`Get the app to find new friends!`),
    subtitle: staticTr(t`Find amazing teammates for your favorite games 🧑‍🤝‍🧑`),
    type: "install",
  },
  profile: {
    title: staticTr(t`Get the app to create your profile!`),
    subtitle: staticTr(
      t`Create your profile and find friends who love the same games 🧑‍🤝‍🧑`
    ),
    type: "install",
  },
  notFound: {
    title: staticTr(t`Oops, we can’t find that!`),
    subtitle: staticTr(t`Trying to download Rune?`),
    type: "install",
  },
}

export function AppPrompt({ standalone }: { standalone?: boolean }) {
  const [appPrompt, setAppPrompt] = useRecoilState($appPrompt)

  //Save latest displayed data, so that it does not disappear while animation of modal being hidden is executed.
  const [latestAppPrompt, setLatestAppPrompt] = useState<AppPromptState | null>(
    null
  )

  useEffect(() => {
    if (appPrompt?.type) {
      webLogger.info({
        action: "webAppPromptShown",
        msg: "Install Rune prompt shown",
        info: {
          type: appPrompt.type,
          subtype: texts[appPrompt.type].type,
        },
      })

      setLatestAppPrompt(appPrompt)
    }
  }, [appPrompt, appPrompt?.type])

  const trackLinkClick = useCallback(() => {
    if (appPrompt?.type) {
      webLogger.info({
        action: "webAppPromptLinkClicked",
        msg: "Install Rune prompt link clicked",
        info: {
          type: appPrompt.type,
          subtype: texts[appPrompt.type].type,
        },
      })
    }
  }, [appPrompt?.type])

  const dismiss = useCallback(() => setAppPrompt(null), [setAppPrompt])

  const visible = !!appPrompt
  const data = latestAppPrompt

  const onShareButtonClick = useCallback(() => {
    trackLinkClick()
  }, [trackLinkClick])

  const content = (
    <PromptBase onClick={dismiss} animated visible={visible} imgUrl={logoImg}>
      {data && (
        <>
          <Title>{texts[data.type].title}</Title>
          <Subtitle>{texts[data.type].subtitle}</Subtitle>
          <Buttons>
            {texts[data.type].type === "install" ? (
              <Button
                primary
                as="a"
                href={webConfig.installRuneUrl.mobile}
                rel="noopener noreferrer"
                onClick={trackLinkClick}
              >
                {staticTr(t`Install Rune`)}
              </Button>
            ) : texts[data.type].type === "share" ? (
              <Button primary onClick={onShareButtonClick}>
                {staticTr(t`Share Link`)}
              </Button>
            ) : null}

            {!standalone && (
              <Button onClick={dismiss}>{staticTr(t`Not Now`)}</Button>
            )}
          </Buttons>
        </>
      )}
    </PromptBase>
  )

  if (standalone) return content

  return (
    <>
      <SimpleCSSTransition visible={visible} duration={animationDuration}>
        <Backdrop />
      </SimpleCSSTransition>
      {content}
    </>
  )
}

const Title = styled.h1`
  ${webTheme.text.headingBold};
  text-align: center;
  padding: 31px 0 12px;
`

const Subtitle = styled.span`
  ${webTheme.text.body};
  opacity: 0.8;
  text-align: center;
  padding-bottom: 21px;
`

const Buttons = styled.div`
  > :not(:first-child) {
    margin-top: 16px;
  }
  width: 100%;
`

const Button = styled.div<{ primary?: boolean }>`
  ${webTheme.text.body};
  text-align: center;
  padding: 15px;
  border-radius: 30px;
  display: block;
  text-decoration: none;
  ${({ primary }) =>
    primary
      ? css`
          background-color: #00c0ff;
          color: white;
        `
      : css`
          color: #00c0ff;
          padding: 0;
        `};
`
