// Import plural rules for all locales
const plurals = require("make-plural/plurals")

function resolveMessages(module) {
  return module.default.messages
}

module.exports = {
  languageData: {
    2: {
      iso6391code: "hi",
      active: false,
      crowdinLocaleCode: "hi",
      momentLocaleCode: "hi",
      catalog: () => import("../../locale/hi/messages").then(resolveMessages),
      plurals: plurals.hi,
    },
    3: {
      iso6391code: "en",
      active: true,
      crowdinLocaleCode: "en",
      momentLocaleCode: "en",
      catalog: () => import("../../locale/en/messages").then(resolveMessages),
      plurals: plurals.en,
    },
    7: {
      iso6391code: "ta",
      active: false,
      crowdinLocaleCode: "ta",
      momentLocaleCode: "ta",
      catalog: () => import("../../locale/ta/messages").then(resolveMessages),
      plurals: plurals.ta,
    },
    15: {
      iso6391code: "zh",
      active: false,
      crowdinLocaleCode: "zh-CN",
      momentLocaleCode: "zh-cn",
      catalog: () => import("../../locale/zh/messages").then(resolveMessages),
      plurals: plurals.zh,
    },
    17: {
      iso6391code: "es",
      active: true,
      crowdinLocaleCode: "es-ES",
      momentLocaleCode: "es",
      catalog: () => import("../../locale/es/messages").then(resolveMessages),
      plurals: plurals.es,
    },
    19: {
      iso6391code: "pt",
      active: true,
      crowdinLocaleCode: "pt-BR",
      momentLocaleCode: "pt",
      catalog: () => import("../../locale/pt/messages").then(resolveMessages),
      plurals: plurals.pt,
    },
    22: {
      iso6391code: "ru",
      active: true,
      crowdinLocaleCode: "ru",
      momentLocaleCode: "ru",
      catalog: () => import("../../locale/ru/messages").then(resolveMessages),
      plurals: plurals.ru,
    },
    23: {
      iso6391code: "ja",
      active: false,
      crowdinLocaleCode: "ja",
      momentLocaleCode: "ja",
      catalog: () => import("../../locale/ja/messages").then(resolveMessages),
      plurals: plurals.ja,
    },
    828: {
      iso6391code: "da",
      active: false,
      crowdinLocaleCode: "da",
      momentLocaleCode: "da",
      catalog: () => import("../../locale/da/messages").then(resolveMessages),
      plurals: plurals.da,
    },
    830: {
      iso6391code: "de",
      active: false,
      crowdinLocaleCode: "de",
      momentLocaleCode: "de",
      catalog: () => import("../../locale/de/messages").then(resolveMessages),
      plurals: plurals.de,
    },
    846: {
      iso6391code: "fil",
      active: false,
      crowdinLocaleCode: "fil",
      momentLocaleCode: "tl-ph",
      catalog: () => import("../../locale/fil/messages").then(resolveMessages),
      plurals: plurals.fil,
    },
    848: {
      iso6391code: "fr",
      active: false,
      crowdinLocaleCode: "fr",
      momentLocaleCode: "fr",
      catalog: () => import("../../locale/fr/messages").then(resolveMessages),
      plurals: plurals.fr,
    },
    899: {
      iso6391code: "it",
      active: false,
      crowdinLocaleCode: "it",
      momentLocaleCode: "it",
      catalog: () => import("../../locale/it/messages").then(resolveMessages),
      plurals: plurals.it,
    },
    908: {
      iso6391code: "kk",
      active: false,
      crowdinLocaleCode: "kk",
      momentLocaleCode: "kk",
      catalog: () => import("../../locale/kk/messages").then(resolveMessages),
      plurals: plurals.kk,
    },
    919: {
      iso6391code: "ko",
      active: false,
      crowdinLocaleCode: "ko",
      momentLocaleCode: "ko",
      catalog: () => import("../../locale/ko/messages").then(resolveMessages),
      plurals: plurals.ko,
    },
    975: {
      iso6391code: "no",
      active: false,
      crowdinLocaleCode: "no",
      momentLocaleCode: "nb",
      catalog: () => import("../../locale/no/messages").then(resolveMessages),
      plurals: plurals.no,
    },
    1037: {
      iso6391code: "sv",
      active: false,
      crowdinLocaleCode: "sv-SE",
      momentLocaleCode: "sv",
      catalog: () => import("../../locale/sv/messages").then(resolveMessages),
      plurals: plurals.sv,
    },
    1059: {
      iso6391code: "tr",
      active: false,
      crowdinLocaleCode: "tr",
      momentLocaleCode: "tr",
      catalog: () => import("../../locale/tr/messages").then(resolveMessages),
      plurals: plurals.tr,
    },
  },
  filePaths: ["locale/en/messages.po"],
  crowdinInfo: {
    projectName: "tincan",
    apiV2Key:
      "416640de5f5d447de86c44982e45f7fd7fb9fc7968e0ae075e2ea4ed421d82c616add79d1681823d",
  },
  processables: [
    { phrase: "Friend Boost", processor: "lowercase" },
    { phrase: "Quick Match", processor: "lowercase" },
    { phrase: "Voice Chat", processor: "lowercase" },
    { phrase: "Hello Message", processor: "lowercase" },
  ],
}
