import { useState, useEffect } from "react"

import { webLogger } from "./logWeb"

export function useLogAppOpen() {
  const key = "opened"

  const [firstTimeOpened, setFirstTimeOpened] = useState(
    () => localStorage.getItem(key) === "true"
  )

  const [sessionOpened, setSessionOpened] = useState(
    () => sessionStorage.getItem(key) === "true"
  )

  useEffect(() => {
    if (!firstTimeOpened) {
      setFirstTimeOpened(true)
      localStorage.setItem(key, "true")
      webLogger.info({
        action: "webAppFirstOpen",
        msg: "Web app opened for the first time",
      })
    }
  }, [firstTimeOpened])

  useEffect(() => {
    if (!sessionOpened) {
      setSessionOpened(true)
      sessionStorage.setItem(key, "true")
      webLogger.info({
        action: "webAppSessionOpen",
        msg: "Web app session opened",
      })
    }
  }, [sessionOpened])

  useEffect(() => {
    webLogger.info({
      action: "webAppOpen",
      msg: "Web app opened",
    })
  }, [])
}
