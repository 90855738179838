import { useEffect } from "react"

import logoImg from "../components/AppInstallPrompt/assets/logo.png"

const images = [logoImg]

export function usePreloadImages() {
  useEffect(() => {
    images.forEach((src) => {
      const img = new Image()

      img.src = src
    })
  }, [])
}
