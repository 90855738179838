import "core-js/actual/array/at"
import "lib/momentSetup"
import "moment/min/locales"

import "./styles/fonts.css"
import "./styles/reset.css"

import React from "react"
import ReactDOM from "react-dom"
import { RecoilRoot } from "recoil"

import { App } from "./App"
import { Global } from "./Global"
import { enableWebLogger } from "./lib/logWeb"

enableWebLogger()

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Global />
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
)
