import { useLogAppOpen } from "./lib/useLogAppOpen"
import { usePreloadImages } from "./lib/usePreloadImages"
import { NotFoundView } from "./views/NotFoundView"

function Router() {
  return <NotFoundView />
}

export function App() {
  useLogAppOpen()

  usePreloadImages()

  return <Router />
}
