import { t } from "@lingui/macro"
import styled from "styled-components/macro"

import { staticTr } from "lib/lingui"

import { webConfig } from "../../config"
import { flex } from "../../lib/flex"
import { webTheme } from "../../styles/webTheme"
import { PromptBase } from "../PromptBase"
import { QRCode } from "../QRCode"

import appStoreImg from "./assets/appStore.png"
import googlePlayImg from "./assets/googlePlay.png"
import logoImg from "./assets/logo.png"

export function AppInstallPromptDesktop({
  title,
  subtitle,
}: {
  title?: string
  subtitle?: string
}) {
  return (
    <PromptBase imgUrl={logoImg}>
      <Title>{title ?? staticTr(t`Get Rune Now!`)}</Title>
      <Subtitle>
        {subtitle ?? staticTr(t`Point your camera at the QR code`)}
      </Subtitle>
      <QRCode value={webConfig.installRuneUrl.desktop} size={182} />
      <StoreButtons>
        <a
          href={webConfig.playStoreUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={googlePlayImg} />
        </a>
        <a
          href={webConfig.appStoreUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={appStoreImg} />
        </a>
      </StoreButtons>
    </PromptBase>
  )
}

const Title = styled.div`
  ${webTheme.text.headingLargeBold};
  text-align: center;
  padding: 23px 0 7px 0;
`

const Subtitle = styled.span`
  ${webTheme.text.subheading};
  opacity: 0.8;
  text-align: center;
  padding-bottom: 18px;
`

const StoreButtons = styled.div`
  ${flex({ horizontal: true, wrap: true, hAlign: "center" })};
  padding-top: 31px;
  > a {
    margin: 0 4px;
  }
  > a img {
    width: 206px;
  }
`
