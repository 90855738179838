import qrcode from "qrcode"
import React, { memo, useEffect, useState } from "react"
import styled from "styled-components/macro"

export const QRCode = memo(
  ({ value, size }: { value: string | undefined; size: number }) => {
    const [svg, setSvg] = useState<string>()

    useEffect(() => {
      if (value) {
        qrcode
          .toString(value, { type: "svg", width: size, margin: 0 })
          .then(setSvg)
      } else {
        setSvg(undefined)
      }
    }, [size, value])

    return svg ? (
      <div data-cy="qrCode" dangerouslySetInnerHTML={{ __html: svg }} />
    ) : (
      <Empty size={size} />
    )
  }
)

const Empty = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: #eee;
  border-radius: 5px;
`
