// todo: remove after we switch to @typescript-eslint/no-unused-vars
/* eslint no-unused-vars: 0 */

import { detect } from "detect-browser"

import { webConfig } from "../config"

export enum LogLevel {
  info = 30,
  warning = 40,
  error = 50,
}

const endpoint = `https://odin-${
  webConfig.stage === "production" ? "production" : "launchpad"
}.rune.ai/functions/tincan-logger`

const browser = detect()
let enabled = false

export function enableWebLogger() {
  enabled = true
}

const log = (level: LogLevel) => (payload: {
  action: string
  msg: string
  err?: object
  info?: object
}) => {
  if (!enabled) return

  const body = {
    clientOccurredAt: new Date(),
    deviceId: "RUNE-WEB",
    level,
    ...payload,
    ...(payload.err instanceof Error && {
      err: { msg: payload.err.toString() },
    }),
    info: { ...payload.info, browser, location },
  }

  if (webConfig.stage !== "production") {
    // eslint-disable-next-line no-console
    console.log("log:", LogLevel[level], JSON.parse(JSON.stringify(body)))
  }

  fetch(endpoint, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    mode: "no-cors",
    body: JSON.stringify(body),
    // eslint-disable-next-line no-console
  }).catch(console.log.bind(console, "tincan-logger request failed"))
}

export const webLogger = {
  info: log(LogLevel.info),
  warn: log(LogLevel.warning),
  err: log(LogLevel.error),
}
