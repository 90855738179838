/*
Set up the i18n object for translations
*/
import { i18n } from "@lingui/core"

import type { AllowedTranslationLanguage } from "store/schema"

import { TranslatableMsg } from "../translatedMsg"

import { isWebApp } from "./isWebApp"

const { languageData } = require("../../scripts/tr/hermes.config")

//This type has to be defined here since we cannot import TranslatableMsg from @lingui/core due to babel plugin failing in tests.
export type TranslatedMsg = TranslatableMsg

const loadedLanguages: { [key: string]: boolean } = {}

// set translated language in AppRouter.js
const setLanguage = async (language: AllowedTranslationLanguage) => {
  if (!loadedLanguages[language]) {
    // Load plural rules into i18n object
    const currentLanguageDataKey = Object.keys(languageData).find(
      (key) => languageData[key].iso6391code === language
    )!

    const { plurals, catalog } = languageData[currentLanguageDataKey]

    i18n.load({
      [language]: await catalog(),
    })

    i18n.loadLocaleData({
      [language]: { plurals },
    })

    loadedLanguages[language] = true
  }

  i18n.activate(language)
}

// Set default language to English
// Keeping this call in case somehow our app doesn't go through AppRouter, in that
// case we can at least see app in english.
if (!isWebApp && process.env.NODE_ENV !== "test") setLanguage("en")

// reason for needing staticTr and dynamicTr is that we need the t macro
// to help us source all the strings we need translating that we aren't
// cataloging already. (see serverTranslatables.po for how we're cataloging
// server strings)

// make sure staticTr is invoked with t/plural macro
// i.e. staticTr(t`some text here ${variable_not_translated}`)
// i.e. staticTr(plural(
//      value: this.state.chosenIds.size,
//      {
//        one: `Choose # ${dynamicTr(this.props.itemSingleLabel)}`,
//        other: `Choose # ${dynamicTr(this.props.itemPluralLabel)}`
//      }))

const staticTr = (string: TranslatedMsg): TranslatedMsg => {
  if (__DEV__ && string.includes("\n")) {
    throw new Error(
      `staticTr() cannot handle multi-line strings. String that causes this error printed below:\n\n${string}\n`
    )
  }

  return string as TranslatedMsg
}

// dynamicTr can be invoked with variable i.e. dynamicTr(variable_translated)
const dynamicTr = (string: string): TranslatedMsg =>
  i18n._(string) as TranslatedMsg

//Only allow string types and not the type returned by t``
const skipTr = <T extends string>(
  string: T extends TranslatedMsg ? never : T
): TranslatedMsg => (string as unknown) as TranslatedMsg

// List of words we never translate.
const noTr = {
  RUNE: "Rune",
}

export { setLanguage, staticTr, dynamicTr, skipTr, noTr }
