import { createGlobalStyle, css } from "styled-components/macro"

import { flex } from "./lib/flex"

// Note - body  touch-action: none; seems to be the only way for now to disable zoom on ios
export const Global = createGlobalStyle`${css`
  html,
  body {
    position: fixed;
    overflow: hidden;
    touch-action: none;
  }
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
  }
  #root {
    ${flex()};
  }
  * {
    box-sizing: border-box;
  }
`}`
