import styled from "styled-components/macro"

import { simpleCSSTransitionStyles } from "../lib/SimpleCSSTransition"

export const Backdrop = styled.div<{
  behind?: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  z-index: ${({ behind }) => (behind ? -1 : "auto")};
  pointer-events: none;
`

export const BackdropAnimated = styled(Backdrop)`
  ${simpleCSSTransitionStyles({ opacity: 0 }, { opacity: 0.5 })};
`
