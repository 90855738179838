export const webConfig = {
  installRuneUrl: installRuneUrl(detectStage()),
  playStoreUrl: "https://play.google.com/store/apps/details?id=ai.rune.tincan",
  appStoreUrl:
    "https://apps.apple.com/us/app/rune-teammates-voice-chat/id1450358364",
  stage: detectStage(),
}

export type Stage = "staging" | "launchpad" | "production"

function detectStage(): Stage {
  switch (location.host) {
    case "app-launchpad.rune.ai":
      return "launchpad"
    case "app.rune.ai":
      return "production"
    default:
      return "staging"
  }
}

function installRuneUrl(stage: Stage) {
  switch (stage) {
    case "staging":
      return {
        desktop: `https://join-alpha.rune.ai/uPWyxoRGRnb`,
        mobile: `https://join-alpha.rune.ai/Rt8oSaGGRnb`,
      }
    case "launchpad":
      return {
        desktop: `https://join-beta.rune.ai/qj001k2GRnb`,
        mobile: `https://join-beta.rune.ai/5W5LHq1GRnb`,
      }
    case "production":
      return {
        desktop: `https://join.rune.ai/QynEF1dHRnb`,
        mobile: `https://join.rune.ai/H2waV49GRnb`,
      }
  }
}
