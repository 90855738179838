import { ReactNode, SyntheticEvent, useCallback } from "react"
import styled, { css } from "styled-components/macro"

import { flex } from "../lib/flex"
import {
  SimpleCSSTransition,
  simpleCSSTransitionStyles,
} from "../lib/SimpleCSSTransition"
import { useScreenInfo } from "../lib/useScreenInfo"

const animationDuration = 300

export function PromptBase({
  imgUrl,
  children,
  animated = false,
  visible = true,
  onClick,
}: {
  animated?: boolean
  imgUrl: string
  children: ReactNode
  visible?: boolean
  onClick?: () => void
}) {
  const { isDesktop, isPortrait } = useScreenInfo()

  const stopClickThrough = useCallback(
    (e: SyntheticEvent) => e.stopPropagation(),
    []
  )

  return (
    <SimpleCSSTransition visible={visible} duration={animationDuration}>
      <Root
        onClick={onClick}
        animated={animated}
        animationDuration={animationDuration}
        centered={isDesktop || !isPortrait}
      >
        <Prompt
          onClick={stopClickThrough}
          centered={isDesktop || !isPortrait}
          big={isDesktop}
        >
          <LogoContainer>
            <LogoImg big={isDesktop} src={imgUrl} />
          </LogoContainer>
          {children}
        </Prompt>
      </Root>
    </SimpleCSSTransition>
  )
}

const Root = styled.div<{
  animated: boolean
  animationDuration: number
  centered: boolean
}>`
  position: absolute;
  width: 100%;
  height: 100%;

  ${({ animated }) =>
    animated
      ? css`
          ${simpleCSSTransitionStyles({ bottom: -400 }, { bottom: 0 })};
        `
      : css``};

  ${({ centered }) =>
    centered
      ? css`
          ${flex({ vAlign: "center", wrap: true, hAlign: "center" })};
          width: 69%;
        `
      : css`
          ${flex({ vAlign: "flex-end", wrap: true, hAlign: "center" })};
        `};
`

const LogoContainer = styled.div`
  position: relative;
  ${flex({ hAlign: "center" })};
`

function calculateDimensions(imgSize: number) {
  const imgBorder = 8
  const outerTopPadding = imgSize / 2
  const imgOffset = -imgSize / 2 - outerTopPadding

  return { imgSize, imgBorder, outerTopPadding, imgOffset }
}

const dimensions = {
  normal: calculateDimensions(136),
  big: calculateDimensions(190),
}

const LogoImg = styled.img<{ big: boolean }>`
  position: absolute;
  background-color: white;
  object-fit: contain;
  ${({ big }) => {
    const { imgOffset, imgBorder, imgSize } = dimensions[big ? "big" : "normal"]

    return css`
      top: ${imgOffset}px;
      padding: ${imgBorder}px;
      border-radius: ${imgSize}px;
      width: ${imgSize}px;
    `
  }};
`

const Prompt = styled.div<{ centered: boolean; big: boolean }>`
  ${({ centered, big }) => {
    const { imgBorder, imgSize } = dimensions[big ? "big" : "normal"]

    return centered
      ? css`
          position: relative;
          top: ${(imgSize / 2 + imgBorder) / 2}px;
        `
      : css`
          margin: 10px;
        `
  }};
  background-color: white;
  border-radius: 16px;
  ${({ big }) => {
    const { outerTopPadding } = dimensions[big ? "big" : "normal"]

    return css`
      padding: ${outerTopPadding}px ${big ? "62px 46px 62px" : "24px 22px 24px"};
    `
  }};
  ${flex({ hAlign: "center" })};
`
